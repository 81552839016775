<template>
    <el-dialog
      v-model="webconfig.LoginDialog"
      align-center
      :width="dialogWidth">
    <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="120px"
    class="demo-ruleForm"
    status-icon
  >
  <el-form-item label="用户名" prop="userName">
      <el-input v-model="ruleForm.userName" />
    </el-form-item>
    <el-form-item label="密码" prop="passWord">
      <el-input v-model="ruleForm.passWord" type="password" show-password/>
    </el-form-item>
    <div class="loginbutton">
    <el-button type="primary" round @click="submitForm(ruleFormRef)">登录</el-button>
    <el-button type="primary" round @click="resetForm(ruleFormRef)">重置</el-button>
</div>
    <div class="forget-login">
<a @click="webconfig.ZhuCeDialog=true,webconfig.LoginDialog=false">立即注册</a>
<a @click="webconfig.BackDialog=true,webconfig.LoginDialog=false">找回密码</a>
    </div>
    <el-divider>
     社交账号登录
    </el-divider>
    <div class="login-bottom">
        <a href="#"><svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Tencent QQ</title><path d="M21.395 15.035a39.548 39.548 0 0 0-.803-2.264l-1.079-2.695c.001-.032.014-.562.014-.836C19.526 4.632 17.351 0 12 0S4.474 4.632 4.474 9.241c0 .274.013.804.014.836l-1.08 2.695a38.97 38.97 0 0 0-.802 2.264c-1.021 3.283-.69 4.643-.438 4.673.54.065 2.103-2.472 2.103-2.472 0 1.469.756 3.387 2.394 4.771-.612.188-1.363.479-1.845.835-.434.32-.379.646-.301.778.343.578 5.883.369 7.482.189 1.6.18 7.14.389 7.483-.189.078-.132.132-.458-.301-.778-.483-.356-1.233-.646-1.846-.836 1.637-1.384 2.393-3.302 2.393-4.771 0 0 1.563 2.537 2.103 2.472.251-.03.581-1.39-.438-4.673zM12.662 4.846c.039-1.052.659-1.878 1.385-1.846s1.281.912 1.242 1.964c-.039 1.051-.659 1.878-1.385 1.846s-1.282-.912-1.242-1.964zM9.954 3c.725-.033 1.345.794 1.384 1.846.04 1.052-.517 1.931-1.242 1.963-.726.033-1.346-.794-1.385-1.845C8.672 3.912 9.228 3.033 9.954 3zM7.421 8.294c.194-.43 2.147-.908 4.566-.908h.026c2.418 0 4.372.479 4.566.908a.14.14 0 0 1 .014.061c0 .031-.01.059-.026.083-.163.238-2.333 1.416-4.553 1.416h-.026c-2.221 0-4.39-1.178-4.553-1.416a.136.136 0 0 1-.014-.144zm10.422 8.622c-.22 3.676-2.403 5.987-5.774 6.021h-.137c-3.37-.033-5.554-2.345-5.773-6.021-.081-1.35.001-2.496.147-3.43.318.063.638.122.958.176v3.506s1.658.334 3.318.103v-3.225c.488.027.96.04 1.406.034h.025c1.678.021 3.714-.204 5.683-.594.146.934.227 2.08.147 3.43zM10.48 5.804c.313-.041.542-.409.508-.825-.033-.415-.314-.72-.629-.679-.313.04-.541.409-.508.824.034.417.315.72.629.68zM14.479 5.156c.078.037.221.042.289-.146.035-.095.025-.165-.009-.214-.023-.033-.133-.118-.371-.176-.904-.22-1.341.384-1.405.499-.04.072-.012.176.056.227.067.051.139.037.179-.006.58-.628 1.21-.208 1.261-.184z"/></svg></a>
    </div>
</el-form>
    </el-dialog>
  </template>
  <script setup>
  import useStore from '@/store';
import { reactive,computed, onMounted, onBeforeUnmount,ref } from 'vue';
import { Login } from '@/api/user';
import { setToken } from '@/utils/token';
import { ElMessage } from 'element-plus'
const {user}=useStore()
const {webconfig}=useStore()


const screenWidth = ref(window.innerWidth)
const dialogWidth = computed(() => screenWidth.value > 768 ? '30%' : '85%')

const handleResize = () => {
  screenWidth.value = window.innerWidth
}

const ruleFormRef = ref()
const ruleForm=reactive({
    userName:'',
    passWord:''
})
let validPassword=(rule,value,callback)=>{
      let reg= /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/
      if(!reg.test(value)){callback(new Error('请输入8~18位字母数字组合'))
      }else{
          callback()
      }
  };
  const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
        Login(ruleForm).then(res=>{
          if(!res.HTTP_Status_Code){
            ElMessage({
    message: '登录成功',
    type: 'success',
  })
  webconfig.LoginDialog=false
            setToken(res.data.Token)
            user.getUser()
            formEl.resetFields()
          }else{
            ElMessage.error(res.msg)
          }
        })
        // .catch(error => {
        // // 打印异常信息
        // console.log(error);})
    } else {
      
      return false
    }
  })
}
const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}
let validUserName=(rule,value,callback)=>{
      let reg= /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/
      if(!reg.test(value)){callback(new Error('请输入6~18位字母数字组合'))
      }else{
          callback()
      }
  };
const rules = reactive({
    userName: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { min: 6, max: 18, message: '请输入6~18位字母数字组合账号', trigger: 'blur' },
    { validator: validUserName, trigger: 'blur' }
  ],
  passWord:[
    {required: true, message: '请输入密码', trigger: 'blur'},
    { min: 8, max: 18, message: '请输入8~18位字母数字组合', trigger: 'blur' },
    { validator: validPassword, trigger: 'blur' }
  ]
})

onMounted(()=>{
    window.addEventListener('resize', handleResize)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
  </script>
  <style scoped>
.loginbutton{
    display: flex;
    width: 100%;
    justify-content: center;
}
:deep(.el-dialog__body){
    margin: 1rem 0;
    padding: 0 0;
  }
.forget-login{
    margin-top:2rem;
    display: flex;
    justify-content: space-between;
}
.login-bottom{
    display: flex;
    justify-content: center;
}
.login-bottom a{
    width: 1.5rem;
    height: 1.5rem;
}

  </style>