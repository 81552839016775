<template>
   <footer class="footer-wrapper" >
        <p>
            © {{webconfig.web.webBirthday.slice(0,4)}} - {{ currentYear }}
             By
            {{webconfig.web.authorName}}
        </p>
        <P>今日统计:浏览量(PV):{{webconfig.count.pv}} 访客数(UV):{{webconfig.count.uv}} IP数:{{webconfig.count.ip}} 平均访问时长:{{webconfig.count.v}}</P>
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color: azure;">
            {{webconfig.web.webBeian}}
        </a>
    </footer>
</template>
<script setup>
import { ref } from 'vue';
import useStore from '@/store'
const {webconfig}=useStore()

const currentYear=new Date().getFullYear()

webconfig.GetCount()
</script>
<style scoped>
.footer-wrapper {
    z-index: 999;
    width: 100%;
    height: 8rem;
    padding: 2.5rem 0;
    font-size: 0.875rem;
    text-align: center;
    color: #fff;
    background: linear-gradient(-45deg, #ec8c69, #e9546b, #38a1db, #23d5ab);
    background-size: 300% 300%;
    -webkit-animation: gradientBG 10s ease infinite;
    animation: gradientBG 10s ease infinite;
   
}

@keyframes gradientBG {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}
</style>
