<template>
    <SideNavBar/>
    <!-- 电脑导航栏 -->
    <header  class="pc">
    <div>
    <div id="header" class ="header">
        <span>{{webconfig.web.webName}}</span>
        <ul class="ul-pc">
            <router-link to="/" >主页</router-link>
            <router-link to="/archive" >归档</router-link>
            <router-link to="/album" >相册</router-link>
            <router-link to="/links" >友链</router-link>
            <router-link to="/message" >留言板</router-link>
            <router-link to="/about" >关于</router-link>
            <router-link to="" @click="centerDialogVisible=true" >搜索</router-link>
            <a v-if="!user.user.id" @click="webconfig.LoginDialog=true">登录</a>
            <template v-else>
                <div class="person">
                <img :src="this.user.user.avatar" >
                <ul class="submenu" >
                    <li class="subitem">
                        <router-link to="/user" class="link">
                           个人中心
                        </router-link>
                    </li>
                    <li class="subitem">
                        <a class="link" @click="loginout()"> 退出 </a>
                    </li>
                </ul>
            </div>
            </template>
        </ul>
            <!-- 手机导航栏 -->
        <ul class="ul-mobile">
            <li><a @click="centerDialogVisible=true"><el-icon :size="30"><Search /></el-icon></a></li>
            <li><a @click="drawer=true"><el-icon :size="30"><User /></el-icon></a></li>
        </ul>
    </div>
    </div>
</header>
<el-drawer
 v-model="drawer" 
 title="I am the title" 
 :with-header="false" 
 size="60%"
 >
   <div class="menu-app">
    <div class="top">
    <img v-lazy="webconfig.web.authorImg">
</div>
<div class="auther-name">
            {{webconfig.web.authorName}}
        </div>
        <div class="blog-container">
            <div class="blog-item">
                <router-link :to="`/archive`" style="color:brown;">
                <div class="blog-name">
                    文章
                </div>
                <div class="blog-count">
                    {{webconfig.web.articleCount}}
                </div>
            </router-link>
            </div>
            <div class="blog-item">
                <router-link :to="`/album`" style="color:brown;">
                <div class="blog-name">
                    相册
                </div>
                <div class="blog-count">
                    {{webconfig.web.albumCount}}
                </div>
            </router-link>
            </div>
            <div class="blog-item">
                <router-link :to="`/`" style="color:brown;">
                <div class="blog-name">
                    标签
                </div>
                <div class="blog-count">
                    {{webconfig.web.labelCount}}
                </div>
            </router-link>
            </div>
        </div>
        <div>
            <ul class="menu">
            <router-link to="/" >主页</router-link>
            <router-link to="/archive" >归档</router-link>
            <router-link to="/album" >相册</router-link>
            <router-link to="/links" >友链</router-link>
            <router-link to="/message" >留言板</router-link>
            <router-link to="/about" >关于</router-link>
            <a v-if="!user.user.id" @click="webconfig.LoginDialog=true">登录</a>
            <template v-else>
                <div class="person">
                <ul class="modelmenu" >
                    <li class="modelsubitem">
                        <router-link to="/user" class="link">
                           个人中心
                        </router-link>
                    </li>
                    <li class="modelsubitem">
                        <a class="link" @click="loginout()"> 退出 </a>
                    </li>
                </ul>
            </div>
            </template>
        </ul>
        </div>
   </div>
  </el-drawer>
    
  <el-dialog
    v-model="centerDialogVisible"
    title="搜索文章"
    :width="dialogWidth"
    align-center
  >
  <div style="height: 400px;">
    <u-search :config="config" style="margin-left: 20px" @submit="submit"></u-search>
    <div>
        <ul style="list-style: none; padding:0">
            <el-divider border-style="dashed"  style="font-size: .8rem;color:red"/>
            <div v-if="displayNo" style=" font-size: 1.2rem">
                找不到您查询的内容：{{sea}}
            </div>
            <div v-if="display" style="height:300px ; overflow-y:auto">
            <li v-for="(article,index) in articles" :key="index">
                <router-link @click="centerDialogVisible=false" :to="`/article/${article.id}`">
                    <div style="display: flex;">
                        <div style=" display: flex;  justify-content: center;  align-items: center; font-size:15px; color:#ee1a1a ">{{index+1}}.</div><h2 class="title">{{article.title}}</h2>
                    </div>
                    <div class="content">
                       {{ article.content }}</div>
                </router-link>
                <u-divider />
            </li>
        </div>
        </ul>
    </div>
  </div>
  </el-dialog>
</template>
<script>
import SideNavBar from './SideNavBar.vue';
import useStore from '@/store';
import { loginOut } from '@/api/user';
import { removeToken } from '@/utils/token';
import { ElMessage } from 'element-plus'
import { refcomputed,computed ,ref,onMounted,onBeforeUnmount} from 'vue';
import router from '@/router';
import { SearchConfig } from 'undraw-ui'
import { search } from '@/api/article';
export default {
  name: 'HelloWorld',
  components:{
        SideNavBar,
  },
  data(){
    return{
        oldScrollTop: 0,
    }
  },mounted() {
    // 监听页面滚动事件
    if(1==1){
    window.addEventListener("scroll", this.scrolling)
    }
  },
  methods: {
    scrolling() {
        let header = document.getElementById('header')
                // 滚动条距文档顶部的距离
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
                    document.body.scrollTop
                // 滚动条滚动的距离
                let scrollStep = scrollTop - this.oldScrollTop;
                // 更新——滚动前，滚动条距文档顶部的距离
                this.oldScrollTop = scrollTop;
                if (scrollStep < 0) {
                    header.classList.remove("bian");
                } else {
                    header.classList.add("bian");
                }
            },
        },
        setup(){
            const articles=ref([
                {
                    id:5,
                    title:'体力消耗几何',
                    content:' #include &lt;stdio.h&gt; #include &lt;stdbool.h&gt; #include &lt;stdlib.h&gt; #include &lt;string.h&gt; // 定义二维数组坐标结构体 typedef struct { int i, j; } Point; // 将二维坐标转换为一维坐标 int to_1d(int i, int j, int N) { return i * N + j; } //t;string.h&gt; // 定义二维数组坐标结构体 typedef struct { int i, j; } Point; // 将二维坐标转换为一维坐标 int to_1d(int i, int j, int N) { return'
                },{
                    id:5,
                    title:'体力消耗几何',
                    content:' #include &lt;stdio.h&gt; #include &lt;stdbool.h&gt; #include &lt;stdlib.h&gt; #include &lt;string.h&gt; // 定义二维数组坐标结构体 typedef struct { int i, j; } Point; // 将二维坐标转换为一维坐标 int to_1d(int i, int j, int N) { return i * N + j; } //t;string.h&gt; // 定义二维数组坐标结构体 typedef struct { int i, j; } Point; // 将二维坐标转换为一维坐标 int to_1d(int i, int j, int N) { return'
                },{
                    id:5,
                    title:'体力消耗几何',
                    content:' #include &lt;stdio.h&gt; #include &lt;stdbool.h&gt; #include &lt;stdlib.h&gt; #include &lt;string.h&gt; // 定义二维数组坐标结构体 typedef struct { int i, j; } Point; // 将二维坐标转换为一维坐标 int to_1d(int i, int j, int N) { return i * N + j; } //t;string.h&gt; // 定义二维数组坐标结构体 typedef struct { int i, j; } Point; // 将二维坐标转换为一维坐标 int to_1d(int i, int j, int N) { return'
                }
            ])
            const displayNo=ref(false)
            const display=ref(false)
            const drawer=ref(false)
            const {webconfig} =useStore()
            const {user}=useStore()
            const sea=ref('')
            const loginout=()=>{
                loginOut().then(res=>{
                    removeToken(),
                    ElMessage({
    message: '注销成功',
    type: 'success',
  })
  user.$reset()
  if (router.currentRoute.value.path==="/user") {
    router.go(-1);
  }
                })
            }
            const screenWidth = ref(window.innerWidth)
            const handleResize = () => {
  screenWidth.value = window.innerWidth
}
const config = ref({
  keywords: ['是小二阿'], // 搜索框关键字滚动
  hotSearchList: [
    '是小二阿'
  ] // top10 热门搜索 最多显示10条数据
})

const submit = async(val) => {
 sea.value=val
 displayNo.value=false
 display.value=false
 const res=await search(val)
 if(!res.HTTP_Status_Code){
    if(res.data.length===0){
        displayNo.value=true
    }else{
    display.value=true
    articles.value=res.data
    }
 }
}
onMounted(()=>{
    window.addEventListener('resize', handleResize)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
            const dialogWidth = computed(() => screenWidth.value > 768 ? '30%' : '85%')
const centerDialogVisible = ref(false)
            return{
                webconfig,user,loginout,drawer,dialogWidth,centerDialogVisible,config,submit,sea,articles,displayNo,display
            }
        }
};

</script>
<style scoped>
.content{
    font-size: 1rem;
    display: -webkit-box;  /*  display: -webkit-box; 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示  */
    -webkit-box-orient: vertical; /*  -webkit-box-orient 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式  */
    -webkit-line-clamp: 3; /*  -webkit-line-clamp用来限制在一个块元素显示的文本的行数 */
     overflow: hidden;
     color: #606266;
}
.title{
    color: rgb(65, 62, 62);
}
:deep(.el-divider--horizontal){
    display: block;
    height: 1px;
    width: 100%;
    margin: 12px 0;
    border-top: 4px #c82f9d var(--el-border-style);
}
.header{
    width: 100%;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    padding: .2rem 1rem;
    justify-content: space-between;
    align-content: center;
    transition: 0.5s;
    z-index: 999;
}
.header ul{
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    list-style: none;
}
.header span{
    position: relative;
    font-size: 1.5rem;
    font-weight: 900;
    letter-spacing: 1px;
    color: aqua;
}
.header ul a{
    position: relative;
    margin: 0 .6rem;
    font-size: 1.5rem;
    font-family: 'fangsong';
    font-weight: 900;
    color: aqua;
    text-decoration: none;
}
.bian{
    padding: .5rem 1rem;
    background: aliceblue;

}
.bian span,.header.bian a{
    color: rgb(203, 8, 63);
}
.beijing{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    z-index: -10;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
}
.ul-mobile{
    display: none !important;
}

.header ul img{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}
.submenu{
    position: absolute !important;
    display:block !important;
}
.person{
    flex-direction:column;
}
.subitem a{
    font: 500 !important;
    font-size: medium !important;
}
.person img:hover+.submenu{
    display: block !important;
}
.menu-app{
    min-height: 100vh;
}
.top{
    justify-content: center;
    display: flex;
}
.top img{
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
}
.auther-name{
    text-align: center;
    font-size: 1.2em;
    font-weight: 800;
    margin-top: .6rem;
}
.blog-container{
    display: flex;
    justify-content: center;
    margin-top: .1rem;
}
.blog-name{
    font-size: 10px;
    text-align: center;
    margin: 6px 6px;
}
.blog-count{
    font-size: 20px;
    font-weight: 100;
    text-align: center;
}
.submenu{
    display: none !important;
    width: 6rem;
    display:list-item;
    justify-content: center;
    right: -1.6rem;
}
.submenu:hover{
    display:block !important;
}
.submenu::before{
    content: '';
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: -2.5rem;
    right: 1.5rem;
}
.submenu::before:hover+.submenu{
    display:block !important;
}
.menu{
    display: grid !important;
    flex-direction:column;
}
.menu a{
    justify-content: center;
    display: flex ;
    align-content: center;
    margin: .3rem 0;
    font-size: large;
    color: rgba(0, 0, 0, 0.852);
}
.menu a:hover{
    background: brown;
}
.modelsubitem{
    display: block !important;
}
@media only screen and (max-width: 768px) {
.ul-pc{
    display: none !important;
}
.ul-mobile{
    display: flex !important;
}
.top img{
    margin-top: 1.5rem;
}
.menu a{
    margin: 1rem 0;
    font-size: 1.5em;
}
.blog-name{
    font-size: 1.2rem;
}
.blog-count{
    font-size: 1.3rem;
}
.header span{
    font-size: 1.3rem;
    font-weight: 400;
}
.bian{
    padding: .1rem .3rem;
}
}
</style>