<template>
    <el-dialog
      v-model="webconfig.UpdateEmail"
      align-center
      :width="dialogWidth">
    <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="120px"
    class="demo-ruleForm"
    status-icon
  >
  <el-form-item label="邮箱" prop="email">
      <el-input v-model="ruleForm.email" />
    </el-form-item>
    <div style="display: flex; justify-content:flex-end; margin-bottom: 1rem;">
    <el-button :disabled="countdown > 0" type="primary" round @click="sendForm(ruleFormRef)">{{ countdown || '发送' }}</el-button>
  </div>
    <el-form-item label="验证码" prop="captcha">
      <el-input v-model="ruleForm.captcha" />
    </el-form-item>
    <div class="loginbutton">
    <el-button type="primary" round @click="submitForm(ruleFormRef)">确定</el-button>
    <el-button type="primary" round @click="resetForm(ruleFormRef)">重置</el-button>
</div>
</el-form>
    </el-dialog>
  </template>
  <script setup>
  import useStore from '@/store';
  import { reactive,computed, onMounted, onBeforeUnmount,ref } from 'vue';
import { updateEmailCaptcha, updateEmail } from '@/api/user';
import { ElMessage } from 'element-plus'
const {webconfig}=useStore()
const {user} =useStore()
const countdown = ref(0)
const ruleFormRef = ref()
const ruleForm=reactive({
  id:user.user.id,
  email:'',
  captcha:'',
})
const screenWidth = ref(window.innerWidth)
const dialogWidth = computed(() => screenWidth.value > 768 ? '30%' : '85%')

const handleResize = () => {
  screenWidth.value = window.innerWidth
}
  const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
     updateEmail(ruleForm).then(res=>{
      if(!res.HTTP_Status_Code){
          ElMessage({
    message: '修改成功',
    type: 'success',
  })
  ruleFormRef.resetFields()
  webconfig.UpdateEmail=false
        }else{
          ElMessage.error(res.msg)
        }
     })
    } else {
      
      return false
    }
  })
}
const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}
const startCountdown = () => {
      countdown.value = 60 // 当按钮被点击时，将倒计时设置为60
      const intervalId = setInterval(() => {
        countdown.value--
        if (countdown.value <= 0) {
          clearInterval(intervalId) // 当倒计时达到0时停止计时
        }
      }, 1000)
    }
const sendForm = (formEl) => {
  if (!formEl) return
 formEl.validateField('email').then(()=>{
  updateEmailCaptcha(ruleForm).then(res=>{
    if(!res.HTTP_Status_Code){
  ElMessage({
    message: '发送成功',
    type: 'success',
  })
 
 }else{
  ElMessage.error(res.msg)
  }
  })
  startCountdown()
 }).catch(error => {
 
})
}

const rules = reactive({
  email:[
        {
          required: true,
          message: '请输入您绑定的邮箱地址',
          trigger: 'blur',
        },
        {
          type: 'email',
          message: '请输入正确的邮箱地址',
          trigger: ['blur', 'change'],
        },
      ],
      captcha:[{required: true, message: '请输入您收到的验证码', trigger: 'blur'}]
})
onMounted(()=>{
    window.addEventListener('resize', handleResize)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
  </script>
  <style scoped>
.loginbutton{
    display: flex;
    width: 100%;
    justify-content: center;
}
:deep(.el-dialog__body){
    margin: 1rem 0;
    padding: 0 0;
  }
.forget-login{
    margin-top:2rem;
    display: flex;
    justify-content: space-between;
}
.login-bottom{
    display: flex;
    justify-content: center;
}
.login-bottom a{
    width: 1.5rem;
    height: 1.5rem;
}

  </style>