import { createRouter, createWebHashHistory } from "vue-router"

const routes=[{
        path:'/',
        name:'home',
        component:() => import("@/views/Home.vue")
    },
    {
        path:'/article/:ArticleId',
        name:'article',
        component:() => import("@/views/Article.vue")
    },
    {
        path:'/archive',
        name:'archive',
        component:() => import("@/views/Archive.vue")
    },
    {
        path:'/album',
        name:'album',
        component:() => import("@/views/Album.vue")
    },
    {
        path:'/links',
        name:'links',
        component:() => import("@/views/Links.vue")
    },
    {
        path:'/about',
        name:'about',
        component:() => import("@/views/About.vue")
    },
    {
        path:'/message',
        name:'message',
        component:() => import("@/views/Message.vue")
    },
    {
        path:'/photo/:PhotoId',
        name:'photo',
        component:() =>import("@/views/Photo.vue")
    },
    {
        // path:'/Carousel1',
        // component:() => import("@/components/wrapper/Carousel1.vue")
    },
    {
        path:'/404',
        name:'404',
        component:() =>import("@/views/404.vue")
    },
    {
        path:'/user',
        name:'user',
        component:() =>import("@/views/User.vue")
    },
    // {
    //     path:'/useravatar',
    //     name:'usera',
    //     component:() =>import("@/components/useravatar")
    // },
    { path: "/:catchAll(.*)", redirect: "/404" },
]

const router=createRouter({
    history:createWebHashHistory(),
    routes,
})

export default router