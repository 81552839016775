import { defineStore } from "pinia";
import { getWebConfig,homeCount,updateVisit } from "@/api/webConfig";


const useWebConfigStore = defineStore("webconfig", {
    state: () => ({ 
    web:{
        id:'',
        webName:'',
        webUrl:'',
        webAbout:'',
        authorName:'',
        authorImg:'',
        platform:'',
        articleHome:'',
        articleTop:'',
        articleCount:'',
        webNotice:'',
        albumCount:'',
        webBirthday:'',
        webVisits:'',
        userAvatar:'',
        labelCount:'',
        webArchive:'',
        webAlbum:'',
        webLink:'',
        webMessage:'',
        webAboutimg:'',
        webNo:'',
        onlinecount:undefined,
        webBeian:'',
    },
    count:{
         pv:0,
         uv:0,
         ip:0,
         v:'',
         zv:0
    },
    firstVisit:true,
    LoginDialog:false,
    ZhuCeDialog:false,
    BackDialog:false,
    UpdateEmail:false,
    ArticleId:undefined,
    }),
  actions: {
 async GetWebConfig() {
      // 从后端获取数据
  await  getWebConfig().then(res=>{
        if(res.data.webconfig){
        this.web.id=res.data.webconfig.id
        this.web.webName=res.data.webconfig.webName
        this.web.webUrl=res.data.webconfig.webUrl
        this.web.webAbout=res.data.webconfig.webAbout
        this.web.authorName=res.data.webconfig.authorName
        this.web.authorImg=res.data.webconfig.authorImg
        this.web.articleHome=res.data.webconfig.articleHome
        this.web.articleTop=res.data.webconfig.articleTop
        this.web.articleCount=res.data.webconfig.articleCount
        this.web.webNotice=res.data.webconfig.webNotice
        this.web.albumCount=res.data.webconfig.albumCount
        this.web.webBirthday=res.data.webconfig.webBirthday
        this.web.webVisits=res.data.webconfig.webVisits+1
        this.web.userAvatar=res.data.webconfig.userAvatar
        this.web.labelCount=res.data.webconfig.labelCount
        this.web.webArchive=res.data.webconfig.webArchive
        this.web.webAlbum=res.data.webconfig.webAlbum
        this.web.webLink=res.data.webconfig.webLink
        this.web.webMessage=res.data.webconfig.webMessage
        this.web.webAboutimg=res.data.webconfig.webAboutimg
        this.web.webNo=res.data.webconfig.webNo
        this.web.webBeian=res.data.webconfig.webBeian
        }
       
      })
      updateVisit().then(res=>{
        
      })
    },
    async GetCount() {
      await homeCount().then((res)=>{
this.count.pv=res.data.pv_count
this.count.ip=res.data.ip_count
this.count.uv=res.data.visitor_count
this.count.v=res.data.avg_visit_time
this.count.zv=res.data.zv
      })
    },
    Setplatform(data){
        this.web.platform=data;
    }
  },
});

export default useWebConfigStore;