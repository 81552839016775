import request from '@/utils/request'


export function getWebConfig() {
    return request({
        url: '/api/webConfig/getwebconfig',
        method: 'get',
        
    })
}

export function updateVisit() {
    return request({
        url: '/api/webConfig/updateVisit',
        method: 'get',
        
    })
}

export function homeCount() {
    return request({
        url: '/api/baidu/homeCount',
        method: 'get',
    })
}

