
// 认证成功后从服务端获取到 Token，然后存储到 Http-only Cookie 中
export function setToken(token) {
  // 将 token 存储到 Http-only Cookie 中
 return window.localStorage.setItem('token',token);
}
export function getToken() {
    // 获取 token
   return window.localStorage.getItem('token');
  }
  export function removeToken() {
    // 删除 token
   return window.localStorage.removeItem('token');
  }