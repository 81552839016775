<template>
    <el-drawer 
    v-model="home"
     title="I am the title" 
     :with-header="false"
     size="70%">
    <div>
        <img class="author" src="https://static.ttkwsd.top/config/9c65807710f54d9d5ad398a78216ebfb.jpg" alt="">
    </div>
    <div class="blog">
        <div class="blog-item">
            <a href="#">
            <div class="name">文章</div>
            <div class="count">11</div>
        </a>
        </div>
        <div class="blog-item">
            <a href="#">
            <div class="name">分类</div>
            <div class="count">11</div>
        </a>
        </div>
        <div class="blog-item">
            <a href="#">
            <div class="name">标签</div>
            <div class="count">11</div>
        </a>
        </div>
    </div>
    <ul class="side-menu">
        <li>主页</li>
        <li>文章</li>
        <li>娱乐</li>
        <li>留言板</li>
        <li>关于</li>
        <li>登录</li>
    </ul>
  </el-drawer>
</template>
<script>
 export default {
    name:'sidenavbar',
    data(){
        return{
            home:false,
        }
    },
    

};
</script>
<style scoped>
.author{
    display: block;
    margin: 0 auto;
    max-width: 10rem;
    padding: .125rem;
    border-radius: 50%;
}
.blog{
    align-content: center;
    display: flex;
    margin-top:.8rem;
    line-height: 1.4;
    justify-content: center;
}
.blog-item{
    padding:0 .2rem;
    width: 100%;
    align-content: center;
}
.blog-item a{
    color: currentColor;
    border: none;
    outline: 0;
    text-decoration: none;
    word-wrap: break-word;
    overflow-wrap: break-word;
    transition: 0.2s;
    cursor: pointer;
    align-content: center;
}
.name{
    font-size: 1.5rem;
    font-weight: 900;
    align-items: center;
    text-align: center;
}
.count{
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
}
.side-menu{
    text-align: center;
    line-height: 3;
    padding: 1rem 1rem 1.25rem;
}
.side-menu li{
    border-radius: .9375rem;
    margin-bottom: .625rem;
}
</style>