import { defineStore } from "pinia";
import { getUser } from "@/api/user";
const useUserStore =defineStore("user",{
    state:()=>({
        user:{
            id:undefined,
            userName:'',
            nickName:'',
            email:'',
            avatar:'',
            userType:undefined,
            userAbout:'',
            level:'',
            grade:''
        }
    }),
    actions:{
        getUser(){
            getUser().then(res=>{
                this.user.id=res.data.id
                this.user.userName=res.data.userName
                this.user.nickName=res.data.nickName
                this.user.email=res.data.email
                this.user.avatar=res.data.avatar
                this.user.userType=res.data.userType
                this.user.userAbout=res.data.userAbout
                this.user.level=res.data.level
                this.user.grade=res.data.grade
            })
            
        }
    }
});

export default useUserStore;