<template> 
<Cobwebs/>
<Back></Back>
<ZhuCe></ZhuCe>
<Login></Login>
<UpdateEmail></UpdateEmail>
<div class="web-app">
  <TopNavBar/>
  <router-view/>
  <FooterNavBar/>
</div>
</template>

<script>
import TopNavBar from './components/layout/TopNavBar.vue';
import FooterNavBar from './components/layout/FooterNavBar.vue';
import Cobwebs from '@/components/texiao/cobwebs'
import useStore from "./store";
import { ref } from 'vue';
import Login from './components/other/Login.vue';
import ZhuCe from './components/other/ZhuCe.vue';
import Back from './components/other/Back.vue';
import UpdateEmail from './components/other/UpdateEmail.vue';
import { getToken } from './utils/token';
import websocketURL from '@/utils/webSocketconfig.js'
export default {
  name: 'App',
  components: {
    TopNavBar,FooterNavBar,Cobwebs,Login,ZhuCe,Back,UpdateEmail
  },
  data(){
    
  },
  created(){
      
  },
  watch: {
		$route: function(to, from) {
		     window.scrollTo(0,0);
			//document.body.scrollTop = 0;
            //document.documentElement.scrollTop = 0;
		},
	},
  methods: {
    Visit(){
      if(this.webconfig.firstVisit){
        this.webconfig.firstVisit=false
      }
    },
  // 判断手机端还是pc端
  // isMobile() {
  //   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  //   return flag;
  //  },
  
},
mounted() {
    // if (this.isMobile()) {
    //   this.platform='mobile';
      
    // } else {
    //   this.platform='pc';
      
    // }
    // console.log(this.platform)
  },
  setup(){
    let platform=ref()
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    
    if(flag){
      platform='mobile';
    }else{
      platform='pc';
    }
    const {user}=useStore()
const  {webconfig}  = useStore();
//设置platform登录平台
    webconfig.Setplatform(platform)
 //后端获取网站配置
 webconfig.GetWebConfig()
 //后端获取用户信息
 const token=getToken()
 if(token){
  user.getUser()
 }

 const socket = new WebSocket(websocketURL.websocketURL)

socket.onopen = function(event) {
  //console.log('WebSocket连接已建立！')
}

socket.onmessage = function(event) {
  // 处理从服务器接收到的消息
  //console.log(event)
  webconfig.web.onlinecount = event.data // 在线人数
}

socket.onclose = function(event) {
 // console.log('WebSocket连接已关闭！')
}


      return{
        webconfig
      }
  }



}
</script>

<style>
body{
  margin: 0;
  padding: 0;

  position: relative;
}
html {
      height: 100%;
    }
.shangsheng{
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: slideUpIn;
}
@keyframes slideUpIn{
  0% {
    opacity: 0;
    transform: translateY(40px);
}
100% {
    opacity: 1;
    transform: translateY(0);
}
}
.archive-container h1{
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: titleScale;
}
@keyframes titleScale{
  0% {
    opacity: 0;
    transform: scale(.7);
}
100% {
    opacity: 1;
    transform: scale(1);
}
}
@media only screen and (max-width: 768px) {
.page-container{
    padding: .3rem !important;
    }
}

</style>
<style scoped>
.web-app{
  min-height: 100vh;
}
</style>
