import request from '@/utils/request'

export function Login(data) {
    return request({
        url: `/user/Login`,
        method: 'post',
        data,
    })
}

export function getUser() {
    return request({
        url: `/user/getUser`,
        method: 'get',
    })
}

export function loginOut() {
    return request({
        url: `/user/loginOut`,
        method: 'get',
    })
}

export function backPassword(data) {
    return request({
        url: `/user/backPassWord`,
        method: 'post',
        data,
    })
}

export function sendEmail(data) {
    return request({
        url: `/user/sendEmail`,
        method: 'post',
        data,
    })
}

export function register(data) {
    return request({
        url: `/user/register`,
        method: 'post',
        data,
    })
}

export function updateEmail(data) {
    return request({
        url: `/api/user/updateEmail`,
        method: 'post',
        data,
    })
}

export function updateEmailCaptcha(data) {
    return request({
        url: `/api/user/updateEmailCaptcha`,
        method: 'post',
        data,
    })
}

export function updateUser(data) {
    return request({
        url: `/api/user/updateUser`,
        method: 'post',
        data,
    })
}

export function getAvatarPolicy() {
    return request({
        url: `/user/getAvatarPolicy`,
        method: 'post',
    })
}