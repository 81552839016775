/**
 * ajax请求配置
 */
import axios from 'axios'
import { getToken, removeToken } from './token'
import { ElMessage } from 'element-plus'
import baseURLconfig from './baseURLconfig'
import qs from 'qs'

// axios默认配置
axios.create({
    withCredentials:true
})
      
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.timeout = 10000  // 超时时间
// axios.defaults.baseURL 请求地址前缀
// User地址
axios.defaults.baseURL = baseURLconfig.baseURL
// tools地址
// axios.defaults.baseURL = 'http://127.0.0.1:8088'
// 微服务地址
// axios.defaults.baseURL = 'http://myzuul.com:9527/xdx'
//axios.defaults.baseURL = 'http://47.115.208.78:8080'
// 整理数据
axios.defaults.transformRequest = function(data) {
    data = JSON.stringify(data)
    return data
}

// 路由请求拦截
axios.interceptors.request.use(
    config => {
        const token=getToken()
        if (token) {
            config.headers['token'] =token// 将 token 添加到请求头参数中
        }
        if (
            config.method === 'post' &&
            (config.headers)['Content-Type'] ===
              'application/x-www-form-urlencoded'
          ) {
            config.data = qs.stringify(config.data)
          }
        return config
    },
    error => {
        return Promise.reject(error.response)
    })
    let isError = false;
// 路由响应拦截
axios.interceptors.response.use(
    response => {
        // if (response.data.code !== 200) {
        //     return console.log(response.data.msg) 
        // } else {
        //     return response.data
        // }
        if(response.data.code===4000){
            if(!isError){
                isError=true
            ElMessage.error("登录失效，请重新登录!")
            removeToken()
            }
        }else{
            isError=false
            return response.data
        }
    },
    error => {
        return Promise.reject(error.response) // 返回接口返回的错误信息
    })
export default axios
