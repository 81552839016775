import request from '@/utils/request'

export function getArticleOne(id) {
    return request({
        url: `/article/getarticleone?id=${id}`,
        method: 'get',
        
    })
}

export function getArticleHome() {
    return request({
        url: `/article/getarticlehome`,
        method: 'get',
        
    })
}

export function getArticleTop() {
    return request({
        url: `/article/getarticletop`,
        method: 'get',
        
    })
}
export function search(data) {
    return request({
        url: `/api/home/search?title=${data}`,
        method: 'get',
    })
}