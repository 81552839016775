import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import lazyPlugin from 'vue3-lazy'
import { createPinia } from "pinia";
import router from './router'
import UndrawUi from 'undraw-ui'
import 'undraw-ui/dist/style.css'



const app = createApp(App)


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

app.use(ElementPlus)
   .use(ViewUIPlus)
   .use(router)
   .use(lazyPlugin, {
    loading: require('@/assets/images/loading.webp'), // 图片加载时默认图片
    error: require('@/assets/images/error.jpg')// 图片加载失败时默认图片
   })
   .use(UndrawUi)
   .use(createPinia())
  //  .use(baiduAnalytics, {
  //   router: router,
  //   siteIdList: [
  //     '668f201945c5643200e8cf874ebb5ce0',
  //     'aca63bed1cb218c98c7e25314766de96'
  //   ],
  //   isDebug: false
  // })
   .mount('#app')
